<template>
  <div id="app">
    <div class='title'>Covid-19 Vaccine Reactions</div>
    <div class='instr'>Updated 10/12/2021</div>
    <br/>
    Our aim is that your story be seen and heard.<br/>
    
    
    
    <div class='button' @click='openForm'><b>Add your story</b></div>    
    <br/>



  <hr/>
    <div class='header'>
      
      <div class='accounts'>Instagram Injury Accounts</div>
    </div>
    <Instagram v-for="ig in injury_alpha" :key="ig" :account="ig" style='display:block;width:300px;margin:auto' />


    <hr/>
    <div class='header'>
      <div class='accounts'>Information</div>
    </div>
    
    <Instagram v-for="ig in info_igs" :key="ig" :account="ig" style='display:block;width:300px;margin:auto'/>
  <br />

  <hr/>
    <div class='header'>       
      <div class='accounts'>Submitted Injury Stories</div><br />
      <div class='instr'>Click an entry for more details</div>
    </div>
      
    
    <Story v-for="story in stories" :key="story._rowNumber" :story="story"  />
<br/>
<hr/>
Please bear with us as this site is actively being developed.<br/>

<div style=''>Questions & Comments:  
  <img class='header-logo' alt="Vue logo" src="./assets/wemail.png" width="150px">
    </div>
Thanks!
  </div>  
</template>

<script>
import Instagram from './components/Instagram'
import Reader from './helpers/reader.js'
import Story from './components/Story'

export default {
  name: 'App',
  data: ()=>{
    return {
      stories: [],
      injury_igs: [
        'kylewarnermtb',
        'denhamhitchcock',
        'kristinditzelacupuncture',
        '_evessalon_',
        'linz.reh',
        'sina.rie',
        'marah_johnson',
        'flicjackson',
        'kentuckyfried_kelsey',
        'resilient.chels',
        'brittanyjouppi',
        'midgygingy',
        'alughmani',
        'jaimie_killen',
        'sarahlou9679',
        'imyallenn',
        'finesse_cookies',
        'aly.moss',
        'embracing_strength',
        'britgalvin',
        'belderes',


      ],
      info_igs:[
        'cvr_overandover',
        'insta_ashleyeverly',
      ]

    }
  },
  computed:{
    injury_alpha(){
      return this.injury_igs.sort();
    }
  },
  mounted: async function(){
    let r = new Reader('1icNGgqX5lhqR-p6NlhN9AFUA9nLDgxFp4W9EIOW6nBE');
    let rows = await r.read();
    this.stories = rows;
  },
  methods:{
    openForm(){
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSdNVLdZopF57kJkB3NNGY0Pw4-n4t2F75YDtIyihq77r2JZkA/viewform', '_blank');
    }
  },
  components: {
    Instagram,
    Story
  }
}
</script>

<style scoped>

.button{
  border-radius: 4px;
  background: green;
  display:inline-block;
  padding:13px;
  margin: 14px;  
  color: white;
  cursor: pointer;
}


.instr{
font-size: .6em
}

.title{
  font-weight: bolder;
  font-size: 18px;
}

.header-logo{
    display: inline;
    vertical-align: middle;
}
.accounts{
  font-weight: bold;
  font-size: 18px;
  display:inline;
} 
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  
  
  margin-top: 30px;
}


</style>
