
//import {google} from 'googleapis'
import { GoogleSpreadsheet } from 'google-spreadsheet'

export default class Reader{

    constructor(spreadsheetId){
        this.spreadsheetId = spreadsheetId;
        
    }

    async read(){

        // Initialize the sheet - doc ID is the long id in the sheets URL
        const doc = new GoogleSpreadsheet(this.spreadsheetId);

        await doc.useApiKey('AIzaSyC7zapZXOZaMKTzgHz3F3Iq9z0S9uEkhV0')
        /*
        // Initialize Auth - see more available options at https://theoephraim.github.io/node-google-spreadsheet/#/getting-started/authentication
        await doc.useServiceAccountAuth({
        client_email: 'data-230@vaxreax.iam.gserviceaccount.com',//process.env.GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC9uQtcMAdgc6LF\ne+rWNH4on8LSTq09XSBAgpqXHY8JFaJ4lfG+fj8h2Ka/RxqDvYpWIXtz43fMN6u6\nG++h3J4CRd97tLZosaFcidtDBZVYZnHL8USSDH0dMkQg7C5lMN+ZzaXK4/eOXjRp\nzIWiNIkDRH55QndM99VwzjByi1dLLSLV04SkbQ6attwrs+LuFJeUDgg9xKzKEuVF\nzrVI4Ky9h2aoBYio8P9K8SAaA1iqAdjOvB/bAlizydrjDGjTaB7udtj8xhtlv3oF\n34teDzXcC+pi/QXncuKwuNIMdECcU/nzo5txVFz7V82W0R9qCbFBhEMLwTRPG1wI\noSd7IaA1AgMBAAECggEACRA0dhUH3S80bYBNCJgvzUJNIYIi0mD93/A0J/M3U/vX\n9QvDAOTfMsiD2r3UnVyqJaOhHzkUsqTgwsekcHjbPPZqjupkD4vgzpWwxyhfxJBn\nuSSh1L7c/KoAknihXejgAkBy9Q+tCMXcB9mQvHBUwv+zA+JfwaEJADGjtdrWqai0\nfcaUugwbBgz/cId1YU7ya9vyvepVNX1uPBXDbvZhAJ41auzvyL0Z5hSyOTSt1uDL\nmQMML/xQycRgwjMbQudc8WvrkwqWLoPsW8dg+0TQ+5ymT76ttTI9zubpAB0XzVgo\n6bY0uulTNehKfNECxJD5w8jO5qedIp+Rv4QfGaRFVwKBgQDhVR8oDg7DvNOYbDRc\nfH0APiATbPlI7A68yhOj5AlBR8C87I4d2q7pO6S9AZTOkOzHhXx24lzXVbmgVd97\n9i2LYKTxBsoGqfknQuLV8Ja+WgzKgGOGDQQSMU/duq2cUCix5RL9Y9fKWjkETqCM\n1dvcsnqYFdo7N+yefIlSeD50qwKBgQDXizwvWl6mFZmBGfvDMYjlGI+oO6F5OS1E\n9fyUoy6Zg3j33mZAhFlEnLKgI0wm7O2LypDGlhj5q+e7z0LrGXbvJAjC8LuEox5k\nfqln9RI2TZ5pBK4vVsQlPTfLyho9U6dtEtMeF7z8GoLwIvwy38ETCDUAb2XCv2Mi\nqidaTsF+nwKBgQCNXtdOTQuMWIK0hWaQg/X02gVLyrnXu2o/ndSWpXH9B+RvY0aC\ninnkkGv5vO08cAZt41zERZ6T5w+v/VBaOIg4gqjZIzZK2gccT8V7Dhe1U0/lGInY\nR4A/XbrK1N0pTc1KVvsECMkRBmvxPNdTpLTtEPkDQ+qH7r1AbcfF+vTMQwKBgAYJ\n1InPEk+80ywWukLhpGAmq/+ejAuFWotQCN9xIuESGdV32Y19J7MQYMCzvHJquMzt\nw9iMY+66ridIyysi734LmGM9s0Mn/rX6BmzvJK94xTNT+j1BRQV2DZYb91g4e7xG\nhruVFqpJY9muYTpbomESHcoBVOx7yB80fR2+jxUpAoGBALfoCzPMVvHnjuvkFsSc\nO8FcaHkXx+lXZLRGrKLVoaSXCizz/pzP44X9FdDmoQzdSOzW5Ih03ld82QJ3UYds\ndBug/1ikaQU+xyZEAOdDp7zHha9d9kc0KyrESXrDuuIcAr2J4dYqdWpqgoOvE3Ma\n2vBs9frXX8QROY0QxY478cFp\n-----END PRIVATE KEY-----\n",
        });
*/
        await doc.loadInfo(); // loads document properties and worksheets
        console.log(doc.title);

        const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
        console.log(sheet.title);
        console.log(sheet.rowCount);

            // read rows
        const rows = await sheet.getRows(); // can pass in { limit, offset }
        
        return rows;
    }
        
    async authorize() {
        // TODO: Change placeholder below to generate authentication credentials. See
        // https://developers.google.com/sheets/quickstart/nodejs#step_3_set_up_the_sample
        //
        // Authorize using one of the following scopes:
        //   'https://www.googleapis.com/auth/drive'
        //   'https://www.googleapis.com/auth/drive.file'
        //   'https://www.googleapis.com/auth/drive.readonly'
        //   'https://www.googleapis.com/auth/spreadsheets'
        //   'https://www.googleapis.com/auth/spreadsheets.readonly'
        let authClient = null;
    
        if (authClient == null) {
            throw Error('authentication failed');
        }
    
        return authClient;
    }
}