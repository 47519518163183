<template>
  <a :href='link' style='text-decoration:none'>
    <img class='header-logo' alt="Instagram" src="../assets/Instagram_Glyph_Gradient_RGB.png" width="15px">
    <div style='display:inline;font-size:1em;'>@{{account}}</div>
  </a>
</template>

<script>
export default {
  props: ['account'],
  computed:{
    link (){
      return 'https://instagram.com/'+this.account
    }
  }
}
</script>

<style>

.header-logo{
    display: inline;
    vertical-align: middle;
}


/* unvisited link */
a:link {
  color: lightblue;
}

/* visited link */
a:visited {
  color: lightgreen;
}

/* mouse over link */
a:hover {
  color: green;
}

/* selected link */
a:active {
  color: yellow;
}


</style>
