<template >
  <div class='story' >
    <div class='title' @click='expanded=!expanded'>
         {{Title}}
    </div>
    <div class='detailsWrapper' v-if="expanded">
      <div class='detailsContent'>
        <div v-if="Days">onset: {{Days}} Days later</div>      
        <div v-if="SpecificIssues">Diagnosed reaction: {{SpecificIssues}}</div>      
        <div v-if="OtherIssues">Other issues:{{OtherIssues}}</div>      
        <div v-if="OnVaers">reported to VAERS</div>      
        <div v-if="fullStory">See my full story <a :href='fullStory'>here</a></div>      
        <Instagram v-if="insta" :account="insta" class='pad-right'/>
        <FaceBook v-if="faceBook" :account="faceBook" class='pad-right' />
        <Twitter v-if="twitter" :account="twitter" />
      </div>
    </div>
  </div>
</template>

<script>
import Instagram from './Instagram';
import FaceBook from './FaceBook';
import Twitter from './Twitter';
export default {
  components: { Instagram, FaceBook, Twitter },
  props: ['story'],
  data() {
    return {

      expanded: false
    }
  },
  computed:{
    Title(){

      let title = '';
      if(this.Alias)
        title+=this.Alias+":";

      if(this.Sex && this.Age && this.Location)
        title+=` ${this.Sex} / ${this.Age} / ${this.Location}`
      else{
        if(this.Sex)        
          title+=" "+this.Sex;
        if(this.Age)
          title+=" "+this.Age;
        if(this.Location)
          title+=" "+this.Location;
      }
      
      if(this.Dose && this.Vaccine)
        title+=", "+this.Dose +" dose of " + this.Vaccine;
      else if(this.Vaccine && this.Vaccine !== "I don't know")
        title+=" "+this.Vaccine +" Vaccine";

      return title;
    },
    Alias(){
      if(this.story._rawData.length<=12)
        return null;
      return this.story._rawData[12];
    },
    Age(){
      return this.story.Age;
    },
    Sex(){
      return this.story["Biological Sex"];
    },
    Location(){
      if(this.story._rawData.length<=13)
        return null;
      return this.story._rawData[13];
    },
    Vaccine(){
      return this.story._rawData[3];
    },
    Dose(){
      return this.story._rawData[4]
    },
    Days(){
      return this.story._rawData[5]
    },
    SpecificIssues(){
return this.story._rawData[6]
    },
    OtherIssues(){
return this.story._rawData[7]
    },
    OnVaers(){
      return this.story._rawData[8] === "Yes";
    },
    insta (){
      return this.story["Instagram account"];
    },
    faceBook(){
      return this.story["Facebook account"];
    },
    twitter(){
      return this.story["Twitter account"];
    },
    fullStory(){
      if(this.story._rawData.length<=14)
        return null;
      return this.story._rawData[14];
    }
  },
  mounted:()=>{
      //console.log(this)
  }
}
</script>

<style>



.detailsWrapper {
  text-align: center;
  width: 80%;
  display:inline-block;
}

.detailsContent {
text-align: left;
}

.header-logo{
    display: inline;
    vertical-align: middle;
}

.story{
  margin: 20px;
  display:block;  
}

.story *{
  font-size: .9em;
}

.title{
  cursor: pointer;
  font-size: .9em;
  font-weight: bold;
  display:block;
}

.pad-right{
  margin-right: 10px;
}
</style>
